@import "./../../../../styles/variables";
@mixin birthVerificationCommons() {
  @media (max-width: 765px) {
  }
  font-family: $font;

  color: $textColor;
}

.birthverification-heading {
  @include birthVerificationCommons();
  font-size: 32px;
  font-weight: bold;
  padding-top: 64px;
  letter-spacing: 0;
  padding-bottom: 16px;
}

.birthverification-content {
  @include birthVerificationCommons();
  color: $textColor;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  padding-top: 10px;
}
.birthverification-info {
  @include birthVerificationCommons();
  font-size: 24px;
  font-weight: 500;
  padding-top: 20px;
}
.birthverification-info1 {
  @include birthVerificationCommons();
  font-size: 16px;
  font-weight: 500;
  padding-top: 20px;
}
.DoD-Range {
  padding-bottom: 20px;
}

.btn-birthverify-info {
  text-align: center;
  padding: 64px 0 104px 0;
}

.birthverify-back-button {
  @include birthVerificationCommons();
  color: $buttonBlue;
  border: 2px solid $buttonBlue;
  background-color: $buttonWhite;
  @media (min-width: 765px) {
    height: 56px;
    width: 160px;
  }
}

.birthverify-next-button {
  @include birthVerificationCommons();
  background-color: $buttonBlue;
  color: $buttonWhite;
  border: 2px solid $buttonBlue;
  text-align: center;
  margin-top: 20px;
  @media (min-width: 765px) {
    height: 56px;
    width: 160px;
    margin-top: 0px;
  }
}

@media (min-width: 765px) {
  .birthVerfiyDateRange {
    width: 600px;
  }
}

.birthverification-delivery-type {
  margin-bottom: 16px;
  @include birthVerificationCommons();
  font-size: 24px;
  font-weight: 500;
}

.tx-radio__label {
  margin-top: -2px;
}

.tx-radio__checkmark {
  height: auto;
  width: auto;
}

.tx-radio {
  align-items: flex-start;
}

.tx-radio__input:checked~.tx-radio__checkmark:after {
  transform: scale(.7);
}